import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Module, Action, Mutation } from 'vuex-module-decorators';
import { PartialDeep } from 'type-fest';
import { OpenClosedApiModule } from './OpenClosedApiModule';
import { Contract } from '~/types/contract';
import { Orderer } from '~/types/orderer';
import { ContractOrder, OrderStatus } from '~/store/entities/contractOrder';
import { XLanguage } from '~/types/content';

dayjs.extend(isBetween);

type KeycloakProfileExtended = Keycloak.KeycloakProfile & { phoneNumber: string };
type DocumentCount = { documents: number; incidents: number };

const makeFeedbackRequest = (feedbackData: any, orderer: any): FormData => {
  const documentFiles = feedbackData.documentFiles ?? [];
  const documentTypes = feedbackData.documentTypes ?? [];
  const incidentFiles = feedbackData.incidentFiles ?? [];
  const allFiles = [...documentFiles, ...incidentFiles];

  feedbackData = {
    externalQualifier: feedbackData.orderExternalQualifier,
    taskDate: feedbackData.taskDate,
    document: {
      weighBridgeTicketNo: feedbackData.weighingNote,
      incident: feedbackData.reportIncident,
      incidentRemark: feedbackData.reportIncidentNotes,
      deliveryNo: feedbackData.deliveryNote,
    },
    partner: {
      email: orderer?.ordererEmail,
      firstname: orderer?.ordererFirstname,
      lastname: orderer?.ordererLastname,
    },
    loggedInUser: orderer?.loggedInUser,
    xlanguage: XLanguage.DE,

    documentAttachments: [
      ...documentFiles.map((file: File, index: number) => {
        return { fileName: file.name, incidentAttachment: false, attachmentDocumentType: documentTypes[index] };
      }),
      ...incidentFiles.map((file: File) => {
        return { fileName: file.name, incidentAttachment: true, attachmentDocumentType: ' ' };
      }),
    ],

    orderFeedbackMetadata: feedbackData.orderFeedbackMetadata.map(
      (
        meta: {
          fieldNo: string;
          unitOfMeasure: string;
          orderNoLineNo: string;
          orderExternalQualifier: string;
          value: string;
        },
        index: number
      ) => {
        return {
          fieldNo: meta.fieldNo,
          orderNoLineNo: meta.orderNoLineNo,
          unitOfMeasure: meta.unitOfMeasure,
          orderExternalQualifier: meta.orderExternalQualifier,
          value: feedbackData.accomplishmentAmounts[index].replace(/\./g, ','),
        };
      }
    ),
  };

  const formData = new FormData();
  allFiles.forEach((file: File) => {
    formData.append(`files`, file);
  });
  formData.append('jsonData', JSON.stringify({ ...feedbackData, files: undefined }));
  return formData;
};

@Module({
  name: 'PartnerOrders',
  namespaced: true,
  stateFactory: true,
  preserveState: false,
})
export default class PartnerOrdersStoreModule extends OpenClosedApiModule {
  customerNumber: string = '';
  realm: string = '';
  profile?: Orderer;
  progress: number = 0;
  documentedFiles?: number | undefined = undefined;
  incidentFiles?: number | undefined = undefined;

  get serviceUrl(): string {
    const state = this.context.state as any;
    return `/portal/partner/${state.customerNumber}/documentation/orders`;
  }

  @Action
  async createOrderfeedback(feedbackData: any) {
    try {
      this.context.commit('resetProgress');
      const formData = makeFeedbackRequest(feedbackData, this.orderer);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: any) => {
          let { progress } = (this.context.state as any).progress;
          progress = (progressEvent.loaded / progressEvent.total) * 100;
          this.context.commit('ordersFeedbackProgress', progress);
        },
      };
      const response = await this.api.post('/portal/partner/documentation/orders/feedback', formData, config);
      setTimeout(() => {
        this.context.commit('ordersFeedbackSuccess', response.data.data);
      }, 1000);
    } catch (e) {
      const response = (e as any).response.data.data;
      if (response && response.documentedFiles) {
        this.context.commit('setDocumentedFiles', response.documentedFiles);
      }
      this.context.commit('ordersFeedbackError', e as string);
    }
  }

  @Mutation
  setDocumentedFiles(counts: DocumentCount) {
    this.documentedFiles = counts.documents;
    this.incidentFiles = counts.incidents;
  }

  @Mutation
  resetDocumentedFiles() {
    this.documentedFiles = undefined;
    this.incidentFiles = undefined;
  }

  @Mutation
  ordersFeedbackSuccess(response: { order: { externalQualifier: string } }) {
    this.openRecords = this.openRecords.filter((order) => order.externalQualifier !== response.order.externalQualifier);
    this.status = { type: 'success' };
    this.progress = 0;
  }

  @Mutation
  ordersFeedbackError() {
    this.status = { type: 'error' };
    this.progress = 0;
  }

  @Mutation
  ordersFeedbackProgress(progress: number) {
    this.progress = progress;
  }

  @Mutation
  resetProgress() {
    this.status = { type: 'loading' };
    this.progress = 0;
  }

  get uploadProgress() {
    return this.progress;
  }

  @Mutation
  setCurrentOrder(record: any) {
    this.record = record;
  }

  get tableModalContent(): object | undefined {
    return {};
  }

  @Mutation
  setContractDetail(record: Contract | undefined) {
    this.status = { type: '' };
    this.record = record;
  }

  @Mutation
  initOrderer(user: KeycloakProfileExtended) {
    this.profile = {
      loggedInUser: user.username as string,
      ordererEmail: user.email as string,
      ordererFirstname: user.firstName as string,
      ordererLastname: user.lastName as string,
      ordererPhoneNumber: user.phoneNumber as string,
    };
  }

  @Mutation
  setCustomerNumber(customerNumber: string) {
    this.customerNumber = customerNumber;
  }

  @Mutation
  setCurrentRealm(realm: string) {
    this.realm = realm;
  }

  get documentedFilesCount(): number | undefined {
    return this.documentedFiles;
  }

  get incidentFilesCount(): number | undefined {
    return this.incidentFiles;
  }

  get currentRecord(): PartialDeep<any> {
    return this.record;
  }

  get orderer(): Orderer | undefined {
    return this.profile;
  }

  get openContractOrders() {
    return this.mapOrders(this.openRecords);
  }

  get openContractOrdersWithoutAval() {
    return this.mapOrders(this.openRecords.filter((order: ContractOrder) => !order.avalMatched));
  }

  get reportedContractOrders() {
    return this.mapOrders(this.closedRecords.filter((order) => order.documentType === OrderStatus.ORDER));
  }

  get reportedContractOrdersWithoutAval() {
    return this.mapOrders(this.closedRecords.filter((order: ContractOrder) => order.documentType === OrderStatus.ORDER && !order.avalMatched));
  }

  get closedContractOrders() {
    // const taskDatePastTwelveWeeks = dayjs().startOf('day').add(2, 'hour').subtract(12, 'week').toISOString();
    // const taskDateToday = dayjs().startOf('day').add(2, 'hour').add(1, 'day').toISOString();

    return this.mapOrders(
      this.closedRecords.filter((order) => order.documentType === OrderStatus.RELEASE || order.documentType === OrderStatus.COMPLETED_ORDER)
      // filter -12 weeks to today
      // .filter((order) => dayjs(order.taskDate).isBetween(taskDatePastTwelveWeeks, taskDateToday, 'day', '[]'))
    );
  }

  get closedContractOrdersWithoutAval() {
    return this.mapOrders(
      this.closedRecords.filter(
        (order) => order.documentType === (OrderStatus.RELEASE || order.documentType === OrderStatus.COMPLETED_ORDER) && !order.avalMatched
      )
    );
  }

  get mapOrders() {
    return (orders: any[]) =>
      orders.map((order: any) => {
        return {
          ...order,
          serviceLocationCity: order.serviceLocationCity,
          serviceLocation: order.serviceLocationName,
          streetAddress: order.serviceLocationAddress ?? order.streetAddress,
          taskDate: dayjs(order.taskDate),
          dispositionDate: dayjs(order.taskDate),
          timestamp: new Date(order.taskDate).getTime(),
          zipCode: order.serviceLocationZipCode,
          attachments: order.numberOfAttachements,
        };
      });
  }

  get ordersCount() {
    return this.openRecords.length;
  }

  get tableRecords() {
    return this.openRecords;
  }
}
